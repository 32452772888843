const isDev = process.env.NODE_ENV === "development";
const isStage = process.env.REACT_APP_STAGE === "stage";

module.exports = {
  api: {
    API_URL: isDev ? "http://localhost:3005" : isStage ? "https://api.stage.hydro.bnet-tech.com" : "https://api.hydro.bnet-tech.com",
  },
  socket: {
    SOCKET_URL: isDev ? "ws://localhost:3005" : isStage ? "wss://api.stage.hydro.bnet-tech.com" : "wss://api.hydro.bnet-tech.com",
    SOCKET_PATH: "/socket.io/",
  }
};